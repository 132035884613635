<template>
  <div class="news">
    <div class="container">
      <div class="all-shops__nav news__crumbs">
        <locale-router-link to="" class="crumbs-link main-page-link">
          {{ $t("home") }}
        </locale-router-link>
        <a class="crumbs-link all-shops-link"> {{ $t("companyNews") }}</a>
      </div>
      <h1 class="title news__title">
        {{ $t("companyNews") }}
      </h1>
      <div class="news__filter">
        <button
          class="news__filter-btn"
          @click="getNews(filters)"
          :class="{ active: isFilterId === 'all' }"
        >
          {{ $t("all") }}
        </button>
        <button
          class="news__filter-btn"
          v-for="(item, index) in newsCategory"
          :key="index"
          @click="getFiltredNews(item.id)"
          :class="{ active: item.id === isFilterId }"
        >
          {{ item.attributes.label }}
        </button>
      </div>

      <news-list v-if="news.length" :items="news" />
      <h3 v-else class="title news__not">
        {{ $t("noNews") }}
      </h3>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { getAllNews, getNewsCategory } from "@/api/news";
export default {
  name: "PageNews",
  data() {
    return {
      news: [],
      newsCategory: [],
      filters: [],
      isShowFilter: false,
      isFilterId: null,
    };
  },
  computed: {
    ...mapState("cityModule", ["selectedCity"]),
  },
  watch: {
    selectedCity: {
      handler(cityId) {
        getAllNews(this.filters, cityId.id).then((data) => {
          this.news = data;
          this.isFilterId = "all";
        });
      },
    },
  },
  components: {
    NewsList: () => import("@/components/news/NewsList.vue"),
  },
  created() {
    getNewsCategory().then((category) => {
      this.newsCategory = category;
      this.newsCategory.forEach((e) => {
        this.filters.push(e.id);
      });
    });
    this.getNews(this.filters);
    getAllNews(this.filters).then((data) => {
      this.news = data;
    });
    this.getFiltredNews(this.filters);
  },
  mounted() {
    this.isFilterId = "all";
  },
  methods: {
    getFiltredNews(filterId) {
      getAllNews(filterId, this.selectedCity.id).then((data) => {
        this.news = data;
      });
      this.isFilterId = filterId;
    },
    getNews(filters) {
      getAllNews(filters, this.selectedCity.id).then((data) => {
        this.news = data;
      });
      this.isFilterId = "all";
    },
  },
};
</script>

<style scoped></style>
